import { Recipe as RecipeSchema } from 'schema-dts'

import { Scores } from '../../components/atoms/Score'
import type {
  NutritionPortionBlock,
  Recipe,
} from '../../graphql/generated/api-graphql'
import { escapeHtmlForJsonLd } from '../../helpers/StringHelpers'
import { transformTimeToISO8601 } from '../../helpers/DateHelpers'

export const toRecipeNutritionFactsTable = (
  t: any,
  field?: NutritionPortionBlock | null
) => [
  ...(field
    ? [
        {
          title: field?.title ?? '',
          rows: [
            ...(field?.energy
              ? [
                  {
                    label: t('energy'),
                    value: field.energy,
                  },
                ]
              : []),
            ...(field?.fat
              ? [
                  {
                    label: t('fat'),
                    value: field.fat,
                  },
                ]
              : []),
            ...(field?.carbohydrates
              ? [
                  {
                    label: t('carbohydrates'),
                    value: field.carbohydrates,
                  },
                ]
              : []),
            ...(field?.proteins
              ? [
                  {
                    label: t('proteins'),
                    value: field.proteins,
                  },
                ]
              : []),
            ...(field?.fibers
              ? [
                  {
                    label: t('fibers'),
                    value: field.fibers,
                  },
                ]
              : []),
            ...(field?.salt
              ? [
                  {
                    label: t('salt'),
                    value: field.salt,
                  },
                ]
              : []),
          ],
        },
      ]
    : []),
]

export const toScoreLetter = (nutriscoreLetter?: string | null) => {
  return nutriscoreLetter && nutriscoreLetter in Scores
    ? Scores[nutriscoreLetter as Scores]
    : undefined
}

export const jsonLdRecipe = (recipe: Recipe, t: any) => {
  const hasNutritionalInformation =
    recipe?.nutrition100gBlock?.energy ||
    recipe?.nutrition100gBlock?.fat ||
    recipe?.nutrition100gBlock?.carbohydrates ||
    recipe?.nutrition100gBlock?.proteins ||
    recipe?.nutrition100gBlock?.fibers ||
    recipe?.nutrition100gBlock?.salt

  const recipeCategory = recipe?.tagsTheme?.[0]?.name ?? ''
  const keywords = recipe?.tags?.map((tag) => tag.name).join(',')
  const hasRating = recipe?.rating && recipe?.ratingCount
  const ingredients = recipe?.ingredients?.map((ingredient) =>
    escapeHtmlForJsonLd(ingredient.text)
  )
  const instructions = recipe?.directions?.map((direction) =>
    escapeHtmlForJsonLd(direction)
  )

  const cookingTime = transformTimeToISO8601(recipe?.cookingTime ?? '')
  const preparationTime = transformTimeToISO8601(recipe?.preparationTime ?? '')

  const jsonLd: RecipeSchema = {
    '@type': 'Recipe',
    name: recipe?.title,
    datePublished: new Date(recipe?.updatedAt).toISOString(),
    description: escapeHtmlForJsonLd(recipe?.introduction ?? ''),
    image:
      recipe?.mainImage?.downloadUrl ?? recipe?.mainImage?.variations?.[0]?.url,
    recipeIngredient: ingredients,
    recipeInstructions: instructions,
    recipeCategory,
    keywords,
    ...(cookingTime && { cookTime: cookingTime }),
    ...(preparationTime && { prepTime: preparationTime }),
    ...(recipe?.budget && {
      estimatedCost: t(`recipe_budget_${recipe?.budget}`),
    }),
    ...(recipe.servings && { recipeYield: `${recipe.servings}` }),
    ...(hasNutritionalInformation && {
      nutrition: {
        '@type': 'NutritionInformation',
        ...(recipe?.nutrition100gBlock?.energy && {
          calories: recipe?.nutrition100gBlock?.energy,
        }),
        ...(recipe?.nutrition100gBlock?.fat && {
          fatContent: recipe?.nutrition100gBlock?.fat,
        }),
        ...(recipe?.nutrition100gBlock?.carbohydrates && {
          carbohydrateContent: recipe?.nutrition100gBlock?.carbohydrates,
        }),
        ...(recipe?.nutrition100gBlock?.proteins && {
          proteinContent: recipe?.nutrition100gBlock?.proteins,
        }),
        ...(recipe?.nutrition100gBlock?.fibers && {
          fiberContent: recipe?.nutrition100gBlock?.fibers,
        }),
        ...(recipe?.nutrition100gBlock?.salt && {
          sodiumContent: recipe?.nutrition100gBlock?.salt,
        }),
      },
    }),
    ...(hasRating && {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: recipe?.rating,
        reviewCount: recipe?.ratingCount,
      },
    }),
  }

  return jsonLd
}
