import { FC, useMemo, useRef } from 'react'
import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { ItemList } from 'schema-dts'

import { VideoProps } from '../../atoms/Video'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { HtmlProps } from '../../atoms/Html'
import { PictureProps } from '../../atoms/Picture'

import * as SC from './styled'

export type VideoListEntry = {
  video?: VideoProps
  image?: PictureProps
  title?: string
  htmlProps?: HtmlProps
  actionButtonProps?: ActionButtonProps
}

export type VideoListProps = MinimalSeoBlock & {
  className?: string
  videoList?: VideoListEntry[]
  onClick?: (index: number) => void
  activeIndex?: number
  playingLabel?: string
}

const VideoList: FC<VideoListProps> = (props) => {
  const { className, htmlTag, videoList, onClick, activeIndex, playingLabel } =
    props

  const videoRef = useRef<HTMLVideoElement>(null)
  const currentVideo: VideoListEntry | undefined = useMemo(
    () => (activeIndex !== undefined ? videoList?.[activeIndex] : undefined),
    [videoList, activeIndex]
  )

  return (
    <SC.Container className={className} as={htmlTag}>
      <Head>
        <script
          {...jsonLdScriptProps<ItemList>({
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: videoList?.map((item, index) => {
              const thumbnailUrl = item?.video?.poster
                ? item?.video?.poster?.match(/^http(s*):/)
                  ? item?.video?.poster
                  : item?.video?.jsonLdBasePath &&
                    item?.video?.jsonLdBasePath + item?.video?.poster
                : item?.video?.sources?.[0].src
              return {
                '@type': 'VideoObject',
                position: index + 1,
                name: item?.title ?? 'Video',
                description: item?.htmlProps?.text,
                thumbnailUrl,
                uploadDate:
                  item?.video?.jsonLdInfo?.uploadDate ??
                  new Date().toISOString(),
                contentUrl: item?.video?.sources?.[0].src ?? '',
              }
            }),
          })}
        />
      </Head>
      {currentVideo && (
        <SC.VideoContainer>
          <SC.StyledVideo
            {...currentVideo.video}
            ref={videoRef}
            key={activeIndex}
            jsonLdBasePath={currentVideo.video?.jsonLdBasePath ?? ''}
            disableJsonLd={currentVideo.video?.disableJsonLd ?? true}
          />
          <SC.Content>
            <SC.Title>{currentVideo.title}</SC.Title>
            <SC.Text {...currentVideo.htmlProps} />
            {currentVideo.actionButtonProps && (
              <SC.Action {...currentVideo.actionButtonProps} />
            )}
          </SC.Content>
        </SC.VideoContainer>
      )}
      <SC.VideoList>
        {videoList?.map((item, i) => {
          const isActive = activeIndex === i
          return (
            <SC.ListContent
              $isActive={isActive}
              key={i}
              onClick={() => onClick && onClick(i)}
            >
              <SC.ThumbnailContainer>
                {item.image ? (
                  <SC.Image {...item.image} />
                ) : item?.video?.poster ? (
                  <SC.Thumbnail
                    src={item?.video?.poster}
                    alt={item?.video?.posterAlt}
                  />
                ) : null}
                {isActive && playingLabel && (
                  <SC.PlayingLabel>{playingLabel}</SC.PlayingLabel>
                )}
              </SC.ThumbnailContainer>
              <SC.TitleList $isActive={isActive}>{item.title}</SC.TitleList>
            </SC.ListContent>
          )
        })}
      </SC.VideoList>
    </SC.Container>
  )
}

export default VideoList
