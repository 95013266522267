import { ActionButtonProps } from '../../components/atoms/ActionButton'
import { DefaultLink, LinkTarget } from '../../graphql/generated/api-graphql'

export function ctaBlockToActionButtonProps(
  data?: DefaultLink,
  label?: string
): ActionButtonProps {
  return {
    text: data?.label ?? label ?? data?.href,
    href: data?.href,
    target: data?.target === LinkTarget.Blank ? '_blank' : '_self',
  }
}
