import styled, { css } from 'styled-components'

import ActionButton from '../../atoms/ActionButton'
import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'
import Video from '../../atoms/Video'
import Picture from '../../atoms/Picture'

export const Container = styled.section`
  overflow: hidden;
`

export const VideoContainer = styled.div`
  background-color: ${({ theme }) =>
    theme.scheme.newsVideoListFill ?? theme.colors.pureWhite};
  display: flex;
  flex-flow: column nowrap;

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    align-items: center;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 2rem 0 0 0;
  color: ${({ theme }) =>
    theme.scheme.newsVideoListVideoTitle ?? theme.colors.ruby};
  text-transform: uppercase;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3}
    margin: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  padding: 2rem;

  @media ${mq(sizes.tablet)} {
    max-width: 40%;
  }
`

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.arapawa};
  margin: 2rem 0;
`
export const Action = styled(ActionButton)`
  align-self: flex-start;
`
export const StyledVideo = styled(Video)`
  align-self: center;
  padding: 0;
  width: 100%;
`

export const VideoList = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
`
export const ListContent = styled.button<{ $isActive: boolean }>`
  display: flex;
  flex-flow: column;
  justify-content: start;
  width: 100%;
  padding: 2rem;
  background-color: ${({ theme }) =>
    theme.scheme.newsVideoListThumbnailFill ?? theme.colors.snow};
  cursor: pointer;
  min-width: 30rem;
  transition: background-color 100ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) =>
      theme.scheme.newsVideoListThumbnailFillHover ?? theme.colors.snow};
  }
  &:focus {
    border: 0.1rem dotted #000;
  }
  &:focus:not(:focus-visible) {
    border: 0;
  }
  &:focus-visible {
    border: 0.1rem dotted #000;
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      &,
      &:hover {
        background-color: ${({ theme }) =>
          theme.scheme.newsVideoListThumbnailFillActive ??
          theme.colors.arapawa};
      }
      ${ThumbnailContainer} {
        background-color: ${({ theme }) => theme.colors.bismark};
      }
    `}

  @media ${mq(sizes.tablet)} {
    min-width: auto;
  }
`
export const ThumbnailContainer = styled.div`
  height: 14.5rem;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.pureWhite};
`
export const Image = styled(Picture)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PlayingLabel = styled.div`
  position: absolute;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.ruby};
  color: ${({ theme }) => theme.colors.pureWhite};
  ${({ theme }) => theme.textStyles.titleH8};
  padding: 0.2rem 0.5rem;
`
export const TitleList = styled.p<{ $isActive: boolean }>`
  margin-top: 1.5rem;
  ${({ theme }) => theme.textStyles.textLarge}
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.pureWhite : theme.colors.arapawa};
`
