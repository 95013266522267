import React, { FC, useEffect } from 'react'
import cx from 'classnames'
import { useFormikContext } from 'formik'

import { FormikFormProps } from '../../form/FormikForm'
import { FormMessagesProps } from '../../form/FormMessages'
import FormikField, { FormikFieldProps } from '../../form/FormikField'
import { usePrevious } from '../../../hooks/usePrevious'

import * as SC from './styled'

export type AllFiltersFormProps = {
  className?: string
  formikForm: FormikFormProps
  messagesProps?: FormMessagesProps
  fields: {
    select?: FormikFieldProps
  }[]
  autoSubmit?: boolean
}

const AllFiltersForm: FC<AllFiltersFormProps> = ({
  className,
  formikForm,
  fields,
  messagesProps,
  autoSubmit,
}) => {
  const AutoSubmit = () => {
    const formik = useFormikContext()
    const previousOpenState = usePrevious(formik.values)

    useEffect(() => {
      if (JSON.stringify(previousOpenState) !== JSON.stringify(formik.values)) {
        formik.submitForm()
      }
    }, [formik, formik.values, previousOpenState])
    return null
  }

  return (
    <SC.Form {...formikForm} className={cx('AllFiltersForm', className)}>
      <SC.Fields $length={fields?.length ?? 0}>
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            {field.select && <FormikField {...field.select} />}
          </React.Fragment>
        ))}
      </SC.Fields>
      {messagesProps && <SC.Errors {...messagesProps} />}
      {autoSubmit && <AutoSubmit />}
    </SC.Form>
  )
}

export default AllFiltersForm
