import { FC } from 'react'
import cx from 'classnames'

import { VideoListProps } from '../VideoList'

import * as SC from './styled'

export type VideoListBlockProps = MinimalSeoBlock & {
  className?: string
  littleTitle?: string
  title?: string
  videoListProps?: VideoListProps
}

const VideoListBlock: FC<VideoListBlockProps> = (props) => {
  const { className, littleTitle, title, videoListProps } = props

  return (
    <SC.Container className={cx('VideoListBlock', className)}>
      {littleTitle && <SC.LittleTitle>{littleTitle}</SC.LittleTitle>}
      {title && <SC.Title>{title}</SC.Title>}
      {videoListProps && <SC.StyledVideoList {...videoListProps} />}
    </SC.Container>
  )
}

export default VideoListBlock
