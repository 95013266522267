export type GraphQlFilter = {
  id: number
  name: string
}

export type GraphQlFilters = {
  [key: string]: GraphQlFilter[]
}

export const getFiltersFromUrl = (tagString: string) =>
  tagString?.split('--')?.reduce((obj, entry) => {
    const split = entry?.split('_')
    return { ...obj, [split?.[0]]: split.slice(1).join('_') }
  }, {} as { [key: string]: string }) ?? {}

export const getTagIdsFromUrl = (tagString: string) =>
  Object.values(getFiltersFromUrl(tagString))?.map((v) => parseInt(v, 10))

export const parseFilters = (
  t: any,
  section?: string,
  tagString?: string,
  keys?: string[]
) =>
  keys?.reduce((obj, name) => {
    const match = tagString?.match(
      new RegExp(`${t(`${section}_filter_${name}_slug`)}_([0-9a-zA-Z_]+)`, 'i')
    )

    const val = match?.[1] ?? ''
    return { ...obj, [name]: val }
  }, {})

export const toFilterString = (
  t: any,
  section: string,
  filters: { [key: string]: string }
) => {
  return Object.keys(filters)
    .reduce((obj, name) => {
      if (filters[name]) {
        obj.push(`${t(`${section}_filter_${name}_slug`)}_${filters[name]}`)
      }
      return obj
    }, [] as string[])
    ?.join('--')
}

export const getFilterOptions = (
  t: any,
  section: string,
  filterName: string,
  filters?: GraphQlFilters
): { label: string; value: string }[] => {
  const options = [
    {
      label: t(`${section}_filter_${filterName}_all`),
      value: '',
    },
  ]

  if (filters?.[filterName]) {
    if (Array.isArray(filters?.[filterName])) {
      return options.concat(
        filters?.[filterName]?.map?.(
          (entry) =>
            ({
              label: entry.name,
              value: entry.id + '',
            } ?? [])
        )
      )
    } else {
      return options.concat(
        Object.entries(filters?.[filterName])?.map(
          ([id, name]) =>
            ({
              label: name,
              value: id + '',
            } ?? [])
        )
      )
    }
  }

  return options
}
