import { PictureProps } from './index'

const defaultProps: PictureProps = {}

export const pictureMocks = {
  ...defaultProps,
  alt: 'Lorem ipsum alt',
  maxWidth: 2000,
  width: 800,
  height: 800,
  withHD: true,
  images: [
    {
      src: '/static/assets/images/tests/300x300__990000.png',
      size: 300,
    },
    {
      src: '/static/assets/images/tests/3200x3200.png',
      size: 3200,
    },
    {
      src: '/static/assets/images/tests/800x800__000099.png',
      size: 800,
    },
    {
      src: '/static/assets/images/tests/400x400.png',
      size: 400,
    },
    {
      src: '/static/assets/images/tests/1600x1600__990099.png',
      size: 1600,
    },
  ],
}

export const imageDiptychBlockMocks = {
  ...defaultProps,
  alt: 'Lorem ipsum alt',
  maxWidth: 800,
  width: 800,
  withHD: true,
  images: [
    {
      src: '/static/assets/images/tests/400x400.png',
      size: 400,
    },
    {
      src: '/static/assets/images/tests/800x800__000099.png',
      size: 800,
    },
    {
      src: '/static/assets/images/tests/1600x1600__990099.png',
      size: 1600,
    },
  ],
}

export const imageMocks = {
  alt: 'Lorem ipsum alt',
  maxWidth: 800,
  width: 800,
  withHD: true,
  images: [
    {
      src: '/static/assets/images/tests/burger.jpg',
      size: 400,
    },
  ],
}

export const imageLogoMocks = {
  alt: 'St Möret - le Goût Primeur',
  maxWidth: 800,
  width: 800,
  withHD: true,
  images: [
    {
      src: '/static/assets/images/tests/brand-logo.png',
      size: 400,
    },
  ],
}

export const imageLogoPositiveMocks = {
  alt: 'Positive Food',
  maxWidth: 800,
  width: 800,
  withHD: true,
  images: [
    {
      src: '/static/assets/images/tests/positive-logo.png',
      size: 400,
    },
  ],
}
