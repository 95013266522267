import styled, { css } from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormMessages from '../../form/FormMessages'
import { mq, sizes } from '../../../theme'

export const Form = styled(FormikForm)``

export const Fields = styled.div<{ $length?: number }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;

  @media ${mq(sizes.tablet)} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${mq(sizes.desktop)} {
    grid-template-columns: ${({ $length }) =>
      $length && $length > 3
        ? css`repeat(4, 1fr)`
        : css`repeat(${$length ?? 3}, 1fr)`};
    grid-gap: 3rem;
  }
`

export const Errors = styled(FormMessages)``
