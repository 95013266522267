import styled from 'styled-components'

export const Container = styled.div<{ $height?: number }>`
  width: 100%;
  height: ${({ $height }) =>
    $height && $height !== -1 ? `${$height / 10}rem` : '99.5rem'};
`
export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`
