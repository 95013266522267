import styled from 'styled-components'

import ActionButton from '../../atoms/ActionButton'
import VideoContent from '../../atoms/Video'
import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'

import { VideoBlockPosition } from './types'

export const VideoBlock = styled.section`
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  max-width: ${({ theme }) => `${theme.sizes.video / 10}rem`};
  margin-left: auto;
  margin-right: auto;
  @media ${mq(sizes.desktop)} {
    display: grid;
    grid-template-columns:
      calc(580 / 1070 * 100%)
      calc(40 / 1070 * 100%)
      calc(450 / 1070 * 100%);
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
  }
`
export const StyledVideo = styled(VideoContent)<{
  $videoPosition?: VideoBlockPosition
}>`
  box-shadow: 0 0.7rem 2rem rgba(0, 0, 0, 0.1);
  @media ${mq(sizes.desktop)} {
    box-shadow: 0 1.4rem 3.5rem rgba(0, 0, 0, 0.1);
    grid-column: ${({ $videoPosition }) =>
      $videoPosition === VideoBlockPosition.Right ? '2 / 4' : '1 / 3'};
    grid-row: 1 / 2;
  }
`
export const Content = styled.div<{
  $videoPosition?: VideoBlockPosition
}>`
  align-self: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: calc(100% - 4rem);
  padding: 3rem 2rem 2rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  & > :not(:first-child) {
    margin-top: 1.5rem;
  }
  @media ${mq(sizes.desktop)} {
    grid-row: 1 / 2;
    grid-column: ${({ $videoPosition }) =>
      $videoPosition === VideoBlockPosition.Right ? '1 / 3' : '2 / 4'};
    align-items: flex-start;
    width: 100%;
    min-height: 82%;
    padding-top: 3rem;
    padding-right: 4rem;
    padding-bottom: 2rem;
    padding-left: calc(40 / 450 * 100% + 4rem);
    text-align: left;
  }
  @media ${mq(sizes.scoping)} {
    padding-right: 5rem;
    padding-left: calc(40 / 450 * 100% + 7rem);
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
  color: ${({ theme }) => theme.colors.ruby};
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH2}
  }
`
export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textCaptions}
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  & > :not(:first-child) {
    margin-top: 0.5rem;
  }
`
export const TextVideo = styled.p`
  ${({ theme }) => theme.textStyles.textCaptions}
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  & > :not(:first-child) {
    margin-top: 0.5rem;
  }
`
export const Action = styled(ActionButton)``
