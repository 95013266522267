import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import VideoList from '../VideoList'

export const Container = styled.section`
  border-radius: 2rem;
  background-color: ${({ theme }) =>
    theme.scheme.newsVideoListFill ?? theme.colors.chablis};
  padding: 2rem 1rem;
  margin: 0 1.5rem;
  @media ${mq(sizes.tablet)} {
    margin: 0;
    border-radius: 3rem;
    padding: 4rem;
  }
`

export const LittleTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  color: ${({ theme }) =>
    theme.scheme.newsVideoListSubtitle ?? theme.colors.ruby};
  text-transform: uppercase;
  text-align: center;
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) =>
    theme.scheme.newsVideoListTitle ?? theme.colors.arapawa};
  text-align: center;
  margin: 1rem 0 3rem 0;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`
export const StyledVideoList = styled(VideoList)``
